import Dialog from "../general/Dialog";
import {useState} from "react";
import {useLockFn, useMount} from "ahooks";
import axios from "../../axios";
import {Link} from "react-router-dom";
import general from "../../general";
import Empty from "../general/Empty";

export default () => {
  const [loading, setLoading] = useState();
  const [datas, setDatas] = useState([]);
  const load = useLockFn(async () => {
    Dialog.loading();
    setLoading(true);
    await axios.get('/activityRebate/gameActivityList')
      .finally(() => {
        Dialog.close();
        setLoading(false);
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setDatas(res.data.data.list);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  return <div className="rebate-rebates">
    {datas.length > 0 ? <div className="items">
      {datas.map(item => <Link to={`/welfare/rebate?id=${item.newid}`} className="item" onClick={() => general.rebate = item}>
        <div className="data">
          <div className="name">{item.title}</div>
          <div className="desc">{item.description}</div>
        </div>
        <div className={`operate ${!item.is_apply_rebate ? 'auto' : ''} ${item.submiting ? 'submiting' : ''} ${item.is_submit ? 'submited' : ''}`}/>
      </Link>)}
    </div> : null}
    {datas.length == 0 && !loading ? <Empty/> : null}
  </div>;
}