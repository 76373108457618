import Empty from "../general/Empty";
import {useLockFn, useMount} from "ahooks";
import {useEffect, useState} from "react";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import dayjs from 'dayjs';

let types = [
  {
    id: 1,
    name: '未使用',
  },
  {
    id: 2,
    name: '已使用',
  },
  {
    id: 3,
    name: '已过期',
  }
];

export default () => {
  const [loading, setLoading] = useState();
  const [current, setCurrent] = useState(types[0]);
  const [datas, setDatas] = useState([]);
  const load = useLockFn(async () => {
    Dialog.loading();
    setLoading(true);
    await axios.get('/coupon/myCouponList', {params: {type: current.id}})
      .finally(() => {
        Dialog.close();
        setLoading(false);
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setDatas(res.data.data.couponList);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  useEffect(() => {
    load();
  }, [current]);

  return <div className="user-voucher">
    <div className="head">
      {types.map(item => <div className={`item ${item == current ? 'active' : ''}`} onClick={() => {
        setDatas([]);
        setCurrent(item);
      }}>{item.name}</div>)}
    </div>
    <div className="body">
      {datas.length > 0 ? <div className="items">
        {datas.map(item => <div className={`item state-${current.id}`}>
          <div className="amount">
            <div className="value">
              <div className="unit">￥</div>
              <div className="number">{item.amount * 1}</div>
            </div>
            <div className="desc">
              {item.meet_amount > 0 ? `满${item.meet_amount * 1}使用` : '任意金额使用'}
            </div>
          </div>
          <div className="info">
            <div className="data">
              <div className="name">
                <div className="type">单游券</div>
                <div className="named">{item.name}</div>
              </div>
              <div className="desc">{item.end_time ? dayjs.unix(item.end_time).format('YYYY-MM-DD') : null}</div>
            </div>
            <div className="state">
              <div className="state-2"><img src="/static/img/voucher-used.png"/></div>
              <div className="state-3"><img src="/static/img/voucher-expire.png"/></div>
            </div>
          </div>
        </div>)}
      </div> : null}
      {datas.length == 0 && !loading ? <Empty icon={<img src="/static/img/voucher-empty.png"/>} text={`暂无${current.name}代金券`}/> : null}
    </div>
  </div>;
}