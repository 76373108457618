import {useLockFn, useMount, useUpdate} from "ahooks";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import {useState} from "react";
import useUrlState from "@ahooksjs/use-url-state";
import general from "../../general";
import Image from "../general/Image";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import Receive from "./Receive";

export default () => {
  const update = useUpdate();
  const [data, setData] = useState(general.gift || {});
  const [params] = useUrlState();
  const load = useLockFn(async () => {
    Dialog.loading();
    await axios.get(`/gift/packInfo?pack_id=${params.id}`)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setData(res.data.data);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  const [receiveData, setReceiveData] = useState();

  return <>
    <div className="gift-gift">
      <div className="body">
        <div className="head">
          <div className="icon"><Image src={data.game_img}/></div>
          <div className="info">
            <div className="name">{data.packname}</div>
            <div className="desc">{data.game_name}</div>
          </div>
        </div>
        {data.card ? <div className="operate">
          <div className="info">礼包码：{data.card}</div>
          <CopyToClipboard text={data.card} onCopy={() => Dialog.info('已复制')}>
            <div className="btn">复制</div>
          </CopyToClipboard>
        </div> : null}
        <div className="data">
          <h1>有效期：</h1>
          <p>{data.term_of_validity}</p>
          <h1>礼包内容:</h1>
          <p>{data.content}</p>
          <h1>使用方法:</h1>
          <p>{data.usage}</p>
        </div>
      </div>
      <div className="foot">
        <div className={`operate ${data.surplus_num < 1 ? 'nothing' : ''} ${data.receiving ? 'receiving' : ''} ${data.is_receive ? 'received' : ''}`} onClick={() => setReceiveData(data)}/>
      </div>
    </div>
    {receiveData ? <Receive data={receiveData} setData={setReceiveData} update={update}/> : null}
  </>;
}
