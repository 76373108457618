import Dialog from "../general/Dialog";
import {useState} from "react";
import {useLockFn, useMount, useUpdate} from "ahooks";
import axios from "../../axios";
import {Link} from "react-router-dom";
import general from "../../general";
import Empty from "../general/Empty";
import {useRecoilState} from "recoil";
import badgeState from "../general/badgeState";
import Receive from "./Receive";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

export default () => {
  const update = useUpdate();
  const [badge, setBadge] = useRecoilState(badgeState);
  const [loading, setLoading] = useState();
  const [datas, setDatas] = useState([]);
  const [current, setCurrent] = useState();
  const load = useLockFn(async () => {
    Dialog.loading();
    setLoading(true);
    await axios.get('/gift/gamePackList')
      .finally(() => {
        Dialog.close();
        setLoading(false);
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        let datas = res.data.data.gameList;
        setDatas(datas);
        datas.length > 0 && setCurrent(datas[0]);
        setBadge(badge => ({...badge, pack: 0}));
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  const [receiveData, setReceiveData] = useState();

  return <>
    <div className={`gift-gifts ${!current?.list?.length && !loading ? 'empty' : ''}`}>
      <div className="body">
        <div className="types">
          {datas.map(item => <div className={`type ${item == current ? 'active' : ''}`} onClick={() => setCurrent(item)}>{item.name}</div>)}
        </div>
        {current?.list?.length ? <div className="items">
          {current?.list?.map(item => <Link to={`/welfare/gift?id=${item.packid}`} className="item" onClick={() => general.gift = item}>
            <div className="data">
              <div className="name">{item.packname}</div>
              <div className="desc">{item.packcontent}</div>
            </div>
            {item.card ? <CopyToClipboard text={item.card} onCopy={() => Dialog.info('已复制')}>
              <div className="operate copy" onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}/>
            </CopyToClipboard> : <div
              className={`operate ${item.surplus_num < 1 ? 'nothing' : ''} ${item.receiving ? 'receiving' : ''} ${item.is_receive ? 'received' : ''}`}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setReceiveData(item);
              }}/>}
          </Link>)}
        </div> : null}
      </div>
      {!current?.list?.length && !loading ? <Empty/> : null}
    </div>
    {receiveData ? <Receive data={receiveData} setData={setReceiveData} update={update}/> : null}
  </>;
}
