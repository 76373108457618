import {useHistory} from "react-router-dom";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import useUrlState from "@ahooksjs/use-url-state";
import {useState} from "react";
import {useInterval} from "ahooks";

export default () => {
  const history = useHistory();
  const [params] = useUrlState();
  const [data, setData] = useState({mobile: params.mobile});

  const submit = () => {
    if (!data.code) {
      Dialog.error('请输入验证码');
      return;
    }
    if (!data.password) {
      Dialog.error('请输入新密码');
      return;
    }
    Dialog.loading();
    axios.post('/user/updatePassword', data)
      .finally(() => {
        Dialog.close();
      })
      .then(({data: res}) => {
        if (res.code != 0) {
          Dialog.error(res.msg);
          return;
        }
        Dialog.info('操作成功', () => history.push(`/passwordlogin`));
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const [coding, setCoding] = useState(false);
  const [codeTime, setCodeTime] = useState(0);
  useInterval(() => {
    if (codeTime) {
      setCodeTime(codeTime - 1);
    }
  }, 1000);
  const code = () => {
    if (coding || codeTime) {
      return;
    }
    setCoding(true);
    axios.post('/sms/getSmsCode', {mobile: params.mobile, type: 'find_password'})
      .finally(() => {
        setCoding(false);
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setCodeTime(60);
      });
  }

  return <div className="account-forget-set">
    <div className="body">
      <div className="tips">手机号：{params.mobile?.replace(/^(\d{3})\d{4}(\d+)/, '$1****$2')}</div>
      <div className="input">
        <input type="text" placeholder="请输入验证码" value={data.code} onChange={e => setData({...data, code: e.target.value})}/>
        <div className={'codebtn' + (coding || codeTime ? ' disable' : '')} onClick={code}>
          {coding ? '获取中' : (codeTime ? (codeTime + '秒后重试') : '获取验证码')}
        </div>
      </div>
      <div className="input">
        <input type="password" placeholder="请输入新密码" value={data.password} onChange={e => setData({...data, password: e.target.value})}/>
      </div>
    </div>
    <div className="btn" onClick={submit}>确定</div>
  </div>
};