import Dialog from "../general/Dialog";
import axios from "../../axios";
import {useState} from "react";
import {useHistory} from "react-router-dom";

export default () => {
  const history = useHistory();
  const [data, setData] = useState({});

  const submit = () => {
    Dialog.loading();
    axios.post('/user/isBindMobile', data)
      .finally(() => {
        Dialog.close();
      })
      .then(({data: res}) => {
        if (res.code != 0) {
          Dialog.error(res.msg);
          return;
        }
        let resData = res.data;
        if (!resData.is_bind) {
          Dialog.alert('提示', '没有绑定手机号码 请通过客服找回', () => history.push('/service'));
          return;
        }
        history.push(`/forget-set?username=${data.username}&mobile=${resData.mobile}`);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  return <div className="account-forget-find">
    <div className="body">
      <div className="tips">请输入想要找回密码的账号</div>
      <div className="input">
        <input type="text" placeholder="请输入账号" value={data.username} onChange={e => setData({...data, username: e.target.value})}/>
      </div>
    </div>
    <div className="btn" onClick={submit}>确定</div>
  </div>
};