import useActiveState from "../general/useActiveState";
import { openUrl } from "../general/Old";
import Dialog from "../general/Dialog";
import { useMount } from "ahooks";
import { useEffect, useState, useContext } from "react";
import axios from "../../axios";
import { ServiceContext } from "../general/ServiceContext";

export default () => {
  const [app] = useActiveState("app");

  const serviceData = useContext(ServiceContext);
  console.log("serviceData------", serviceData);

  const qq = () => {
    axios.post("app/csButtonClick");
    if (app.branch === "old") {
      openUrl(serviceData.qqUrl);
    } else {
      window.location.href = `sdk://view?url=${encodeURIComponent(
        serviceData.qqUrl
      )}`;
    }
  };

  const wx = () => {
    if (app.username === "iosceshi" || app.username === "apkceshi") {
      axios.post("app/csButtonClick");
      if (serviceData.wxUrl.startsWith("weixin://")) {
        window.location.href = serviceData.wxUrl;
        return;
      }
    }
    if (app.branch === "old") {
      openUrl(serviceData.wxUrl);
    } else {
      window.location.href = `sdk://browser?url=${encodeURIComponent(
        serviceData.wxUrl
      )}`;
    }
  };

  const hasApp = (packname) => {
    try {
      if (window.aoyou.hasApp(packname)) {
        Dialog.alert("hasApp", `有 ${packname}`);
      } else {
        Dialog.alert("hasApp", `没有 ${packname}`);
      }
    } catch (e) {
      Dialog.alert("error", e.message);
    }
  };

  const [scheme, setScheme] = useState();

  const hasAppIos = (scheme) => {
    setScheme(scheme);
    try {
      window.webkit.messageHandlers.hasApp.postMessage({
        scheme: scheme,
        func: "checkAPPIsExist",
      });
    } catch (e) {
      Dialog.alert("error", e.message);
    }
  };

  useEffect(() => {
    window.checkAPPIsExist = (e) => {
      console.log(e);
      Dialog.alert("hasApp", `${scheme} ${e ? "有" : "没有"}`);
    };
  }, [scheme]);

  useMount(() => {
    window.pullupapp = (e) => {
      console.log(e);
      // Dialog.alert('pullupapp', `pullupapp ${e?.app}`);
    };
  });

  return (
    <div className="service-index">
      <div className="service-container">
        {/* {serviceData.qqUrl && (
          <div onClick={qq} className="btn">
            联系qq客服
          </div>
        )} */}

        {serviceData.img_url ? (
          <div className="service-img">
            <img src={serviceData.img_url} alt="" />
            <p>请您自行保存图片，扫描添加客服后进行咨询</p>
          </div>
        ) : (
          <div onClick={wx} className="btn">
            联系微信客服
          </div>
        )}

        {/* {0 ? (
          <>
            <a href="mqqapi://" className="btn">
              mqqapi://
            </a>
            <a href="snssdk1128://" className="btn">
              snssdk1128://
            </a>
            <a href="kwai://" className="btn">
              kwai://
            </a>
          </>
        ) : null} */}
      </div>
      <div className="service-version">版本：{app.sdkVersion.toString()}</div>
      {/*<a href="box9917://" className="btn">拉起9917盒子</a>
    <div onClick={() => hasApp('com.aoyou.gamebox')} className="btn">安卓是否安装9917盒子</div>
    <div onClick={() => hasApp('box9917://')} className="btn">安卓是否安装9917盒子（scheme）</div>
    <div onClick={() => hasApp('com.maiyou.gamebox')} className="btn">安卓是否安装麦游盒子</div>
    <div onClick={() => hasAppIos('box9917://')} className="btn">iOS是否安装9917盒子</div>
    <div onClick={() => hasAppIos('alipay://')} className="btn">iOS是否安装支付宝</div>*/}
    </div>
  );
};
