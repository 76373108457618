import { useThrottleEffect } from "ahooks";
import config from "../../config";
import useUrlState from "@ahooksjs/use-url-state";
import useActiveState from "./useActiveState";
import React from "react";
import $ from "jquery";

const VConsole = React.lazy(() => import("./VConsole"));

let debug;
export default () => {
  const [params] = useUrlState();
  const [app] = useActiveState("app");
  useThrottleEffect(() => {
    if (
      !debug &&
      (!config.prod ||
        (params.username &&
          ["apkceshi", "iosceshi", "test07"].includes(params.username)) ||
        app.debug)
    ) {
      setTimeout(
        () =>
          console.info({
            width: $("body").width(),
            height: $("body").height(),
          }),
        1024
      );
      debug = true;
    }
  }, [params.username, app.debug]);

  return <>{debug && <VConsole />}</>;
};
