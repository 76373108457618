const env = process?.env || {};

export const name = env.REACT_APP_NAME;
export const version = env.REACT_APP_VERSION;

env.REACT_APP_ENV = env.REACT_APP_ENV || env.NODE_ENV;

export const dev = env.REACT_APP_ENV.startsWith("dev");
export const test = env.REACT_APP_ENV.startsWith("test");
export const prod = env.REACT_APP_ENV.startsWith("pro");

const config = {
  name,
  version,
  env: env.REACT_APP_ENV,
  dev,
  test,
  prod,
  url: env.REACT_APP_URL || "/api",
  tencentCaptcha: {
    appid: "2092825876",
  },
};

export default config;
