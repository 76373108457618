import {Link} from "react-router-dom";
import {useState} from "react";
import general from "../../general";
import useUrlState from "@ahooksjs/use-url-state";
import {useLockFn, useMount} from "ahooks";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import dayjs from 'dayjs';
import {ExclamationOutlined} from "@ant-design/icons";

export default () => {
  const [data, setData] = useState({});
  const [params] = useUrlState();
  const load = useLockFn(async () => {
    Dialog.loading();
    await axios.get(`/activityRebate/rebateInfo?id=${params.id}`)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setData(res.data.data);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  return <div className="user-rebate">
    <div className="data">
      <div className="field">
        <div className="name">游戏名称</div>
        <div className="value">{data.game_account}</div>
      </div>
      <div className="field">
        <div className="name">申请账号</div>
        <div className="value">{data.game_name}</div>
      </div>
      <div className="field">
        <div className="name">充值时间</div>
        <div className="value">{data.pay_time ? dayjs.unix(data.pay_time).format('YYYY-MM-DD') : null}</div>
      </div>
      <div className="field">
        <div className="name">游戏区服</div>
        <div className="value">{data.server_name}</div>
      </div>
      <div className="field">
        <div className="name">角色名</div>
        <div className="value">{data.role_name}</div>
      </div>
      <div className="field">
        <div className="name">角色ID</div>
        <div className="value">{data.role_id}</div>
      </div>
      <div className="field">
        <div className="name">备注</div>
        <div className="value">{data.remark}</div>
      </div>
    </div>
    <div className="info">
      <div className="time">{data.add_time ? dayjs.unix(data.add_time).format('YYYY-MM-DD') : null}</div>
      <div className="amount">充值金额：<span>￥{data.apply_pay_gear}元</span></div>
    </div>
    <div className="tips">
      <div className="title">温馨提示</div>
      <div className="desc">
        <p><ExclamationOutlined className="icon" />返利发放：晚9点前提交的返利均会在一个工作日发放</p>
        <p><ExclamationOutlined className="icon" />返利形式：游戏内发放（邮件领取/直接到角色上）激活</p>
      </div>
    </div>
    <div className="opt">
      <Link to="/service" className="btn">联系客服</Link>
      <div className="desc">此笔返利没有收到或有任何疑问（请在15天内提交申请）</div>
    </div>
  </div>;
}