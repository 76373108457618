import { useHistory, Link } from "react-router-dom";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import { useState } from "react";
import { useInterval } from "ahooks";
import useActiveState from "../general/useActiveState";

export default () => {
  const history = useHistory();
  const [app, setApp] = useActiveState("app");
  const [data, setData] = useState({});

  const submit = () => {
    if (!data.username) {
      Dialog.error("请输入手机号");
      return;
    }
    if (!data.code) {
      Dialog.error("请输入验证码");
      return;
    }
    Dialog.loading();
    axios
      .post("/user/mobileLogin", data)
      .finally(() => {
        Dialog.close();
      })
      .then(({ data: res }) => {
        if (res.code != 0) {
          Dialog.error(res.msg);
          return;
        }
        let resData = res.data;
        /*setApp({
          username: resData.username,
          token: resData.token,
        });*/
        history.push(
          resData.autoLogin && false
            ? `/game?altUsername=${resData.altUsername}`
            : "/alt"
        );
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  };

  const [coding, setCoding] = useState(false);
  const [codeTime, setCodeTime] = useState(0);
  useInterval(() => {
    if (codeTime) {
      setCodeTime(codeTime - 1);
    }
  }, 1000);
  const code = () => {
    if (!data.username) {
      Dialog.error("请输入手机号");
      return;
    }
    if (coding || codeTime) {
      return;
    }
    setCoding(true);
    axios
      .post("/sms/getSmsCode", { mobile: data.username, type: "login" })
      .finally(() => {
        setCoding(false);
      })
      .then((res) => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setCodeTime(60);
      });
  };

  return (
    <div className="account-phone-login">
      <div className="body">
        <div className="tips">无需注册</div>
        <div className="input">
          <input
            type="text"
            placeholder="请输入手机号"
            value={data.username}
            onChange={(e) => setData({ ...data, username: e.target.value })}
          />
        </div>
        <div className="input">
          <input
            type="text"
            placeholder="请输入验证码"
            value={data.code}
            onChange={(e) => setData({ ...data, code: e.target.value })}
          />
          <div
            className={"codebtn" + (coding || codeTime ? " disable" : "")}
            onClick={code}
          >
            {coding
              ? "获取中"
              : codeTime
              ? codeTime + "秒后重试"
              : "获取验证码"}
          </div>
        </div>
      </div>
      <div className="btn" onClick={submit}>
        登录
      </div>
      <div className="agreement">
        登录即同意
        <Link to={`/agreement?url=${app.agreementList?.login?.url}`}>
          《用户协议》
        </Link>
        和
        <Link to={`/agreement?url=${app.agreementList?.privacy?.url}`}>
          《隐私政策》
        </Link>
      </div>
    </div>
  );
};
