import '../../css/old.css';
import {useMount} from "ahooks";
import general from "../../general";

const isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

export const openUrl = (url) => {
  try {
    if (isIos) {
      window.openWebView(url);
    } else {
      window.maiyou.openUrl(url);
    }
  } catch (e) {
    window.location.href = url;
  }
}

export const openBoxApp = function (package_name, url) {
  try {
    if (isIos) {
      if (url) {
        window.openAppBox(package_name, url);
      } else {
        window.openAppBox(package_name);
      }
    } else {
      if (url) {
        window.maiyou.openApp(package_name, url);
      } else {
        window.maiyou.openApp(package_name);
      }
    }
  } catch (e) {
    if (url) {
      openUrl(url);
    }
  }
};

export const openBoxApp2 = () => {
  const {app} = general;

  if (isIos) {
    var res = openUrl('box9917://');
    if (res == 'undefined' || res == undefined) {
      openUrl(`http://app.9917.cn?a=${app.agent}&brand=${app.brand}`);
    }
  } else {
    openBoxApp('com.aoyou.gamebox', `http://app.9917.cn?a=${app.agent}&brand=${app.brand}`);
  }
}

export default () => {
  useMount(() => window.document.title = '用户中心');
  return null;
}