import "babel-polyfill";
import promiseFinally from "promise.prototype.finally";
import ProxyPolyfillBuilder from "proxy-polyfill/src/proxy";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import URI from "urijs";
import fastclick from "fastclick";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { RecoilRoot } from "recoil";
import Index from "./view/Index";

promiseFinally.shim();

let replace;
let uri = URI(window.location.href);
let query = uri.query(true);
if (Array.isArray(query.altUsername) && query.altUsername.length > 1) {
  query.altUsername = query.altUsername
    .map((data) => {
      let uri2 = URI(data);
      let query2 = uri2.query(true);
      if (query2.roleId !== undefined) {
        query.roleId = query2.roleId;
        return null;
      }
      return data;
    })
    .filter((data) => data)[0];
  replace = true;
}
for (const name in query) {
  let value = query[name];
  if (Array.isArray(value)) {
    let newValue = Array.from(new Set(value));
    if (newValue.length !== value.length) {
      replace = true;
      query[name] = newValue;
    }
  }
}
uri.query(query);
if (/*window.location.href !== uri.toString() || */ replace) {
  window.location.replace(uri.toString());
}

if (!window.Proxy) {
  window.Proxy = window.ProxyPolyfill = ProxyPolyfillBuilder();
}
dayjs.locale("zh-cn");
window.pullupapp = () => {
  console.log("pullupapp");
};
fastclick.attach(document.body);

ReactDOM.render(
  <>
    <React.Suspense fallback={null}>
      <Router>
        <RecoilRoot>
          <Index />
        </RecoilRoot>
      </Router>
    </React.Suspense>
  </>,
  document.getElementById("root")
);
