import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useLockFn, useMount, useUpdate, useLocalStorageState } from "ahooks";
import { LeftOutlined } from "@ant-design/icons";
import { selector, useRecoilState, useRecoilValue } from "recoil";
import URI from "urijs";
import dayjs from "dayjs";

import route from "../../route";
import axios from "../../axios";

import Dialog from "../general/Dialog";
import Active from "./Active";
import badgeState from "./badgeState";

import useActiveState from "./useActiveState";
import ActiveContext from "./ActiveContext";
import { ServiceContext } from "./ServiceContext";

const SideScreen = (props) => {
  useMount(() => {
    axios.post("/msg/setMembersRead");
  });

  const cdata = useContext(ActiveContext);

  let title = cdata.title || props.title;
  let operate = cdata.operate;

  return (
    <div className={"side-screen" + (title ? " has-title" : "")}>
      {title || operate ? (
        <div className="side-title">
          <div className="title">{title}</div>
          {operate}
        </div>
      ) : null}
      <div className="side-body">
        <props.View router={props.router} />
      </div>
    </div>
  );
};

const SideNavigation = (props) => {
  let history = useHistory();
  const cdata = useContext(ActiveContext);
  let title = cdata.title || props.title;

  return (
    <>
      <div className="nav-title">
        <LeftOutlined
          className="back"
          onClick={() => {
            if (window.history.length > 2) {
              history.goBack();
            } else {
              history.push("/user");
            }
          }}
        />
        <div className="title">{title}</div>
        <div className="operate"></div>
      </div>
      <div className="nav-body">
        <props.View />
      </div>
    </>
  );
};

const SideContainer = ({ title, View, active, router }) => {
  let history = useHistory();
  let location = useLocation();

  const [views, setViews] = useState([]);
  useEffect(() => {
    if (active) {
      let uri = URI(window.location.href);
      setViews((_views) => {
        let views = [..._views];
        let url = uri.pathname() + uri.search();
        let pathname = uri.pathname();
        let router = route.navigation.find((item) => item.path === pathname);
        if (router) {
          views.reverse();
          let index = views.findIndex((item) => item.url === url);
          views.reverse();
          if (index > 0) {
            while (views[views.length - 1].url !== url) {
              views.pop();
            }
          }
          if (index === -1) {
            views.push({ ...router, query: uri.query(true), url });
          }
        } else {
          views = [];
        }
        // console.log([...views]);
        return [...views];
      });
    }
  }, [history, location, active]);

  return (
    <Active className="side-item" active={active}>
      <Active
        className="side-body-container"
        active={active && views.length === 0}
      >
        <SideScreen {...{ title, router, View }} />
      </Active>
      <Active className="side-views" active={active && views.length > 0}>
        {views.map((item, index) => (
          <Active
            className="side-navigation"
            active={active && index + 1 === views.length}
          >
            <SideNavigation
              {...{
                ...item,
                View: item.view,
                active: active && index + 1 === views.length,
              }}
            />
          </Active>
        ))}
      </Active>
    </Active>
  );
};

const badgeSelectorState = selector({
  key: "badgeGroup",
  get: ({ get }) => {
    const badge = { ...get(badgeState) };
    badge.user = badge.msg;
    badge.welfare = badge.pack || badge.voucher;
    console.log(badge);
    return badge;
  },
});

const Side = () => {
  const location = useLocation();
  const history = useHistory();
  const [app] = useActiveState("app");
  const update = useUpdate();
  useEffect(() => {
    if (["youshangyun", "suqiyun"].includes(app.source)) {
      route.side = route.side.filter((item) => item.id !== "service");
      update();
    }
  }, [app.source]);
  const [badge, setBadge] = useRecoilState(badgeState);
  const badgeSelector = useRecoilValue(badgeSelectorState);
  const [router, setRouter] = useState();
  useEffect(() => {
    let router = route.side.find((item) =>
      location.pathname.startsWith(item.path)
    );
    router && setRouter(router);
  }, [location]);

  // 当前路由为客服，不显示左侧导航栏
  const [nouser, setNouser] = useState(
    location.pathname.startsWith("/service")
  );

  const badgeLoad = useLockFn(async () => {
    axios.get("/user/personalCenter").then(({ data: resData }) => {
      console.log("我是侧边栏获取消息总数-------");
      if (resData.code != 0) {
        return;
      }
      let datas = resData.data?.is_read || [];
      let badge = {};
      for (const data of datas) {
        badge[data.type] = data.num || data.is_read;
      }
      setBadge(badge);
    });
  });
  // useMount(badgeLoad);

  useEffect(() => {
    // 首次加载时获取一次数据
    badgeLoad();

    // 每 5 分钟（300,000 毫秒）执行一次请求
    const intervalId = setInterval(badgeLoad, 30000);

    // 清除定时器，防止内存泄漏
    return () => clearInterval(intervalId);
  }, []);

  // 客服相关数据
  const [serviceIsShow, setServiceIsShow] = useState(true);
  // {
  //   qqUrl: "http://q.url.cn/CDJ3Xl?_type=wpa&qidian=true",
  //   wxUrl: "https://wpa1.qq.com/IBW41dK4?_type=wpa&qidian=true",
  // }
  let [serviceData, setServiceData] = useLocalStorageState(
    `service_${app.gameId}_${app.serverId}`,
    {}
  );

  useMount(() => {
    if (serviceData.wxUrl) {
      setServiceIsShow(true);
    } else {
      setServiceIsShow(false);
    }

    // 当前的秒级时间
    if (dayjs().unix() - (serviceData.time || 0) > 3600 * 2) {
      Dialog.loading();
      axios
        .get("/getService")
        .finally(() => Dialog.close())
        .then(({ data: resData }) => {
          if (resData.code != 0) {
            Dialog.error(resData.msg);
            return;
          }

          console.log("resData.data0-------------", resData.data);

          serviceData = {
            ...resData.data,
            time: dayjs().unix(),
          };
          setServiceData({
            ...resData.data,
            time: dayjs().unix(),
          });
          console.log("serviceData===============", serviceData);

          if (serviceData.wxUrl) {
            setServiceIsShow(true);
          } else {
            setServiceIsShow(false);
          }
        })
        .catch((error) => Dialog.error(error.message));
    }
  });

  console.log("00000000----------", app.agreementList);

  return (
    <div className={`side-container ${nouser ? "nouser" : ""}`}>
      <div className="side-menu">
        {route.side.map((item) => {
          if (item.id === "service" && serviceIsShow === false) {
            return null;
          } else if (
            item.id === "trades" &&
            app.agreementList?.showtrade === 0
          ) {
            return null;
          } else {
            return (
              <div
                className={`item ${badgeSelector[item.id] ? "badge" : ""} ${
                  item == router ? "active" : ""
                }`}
                onClick={() => {
                  if (!location.pathname.startsWith(item.path)) {
                    history.replace(item.path);
                  }
                }}
                onDoubleClick={() => history.replace(item.path)}
              >
                <div className="icon">{item.icon}</div>
                <div className="text">{item.name}</div>
              </div>
            );
          }
        })}
      </div>
      <div className="side-main">
        {route.side.map((item, index) => (
          <ServiceContext.Provider value={serviceData}>
            <SideContainer
              key={index}
              {...{
                ...item,
                View: item.view,
                router: item,
                active: item == router,
              }}
            />
          </ServiceContext.Provider>
        ))}
      </div>
    </div>
  );
};

export { SideContainer, SideNavigation };
export default Side;
